import React from 'react';
import './App.css';
import './Audit.css';
import { Link, withRouter } from "react-router-dom";

import protocol from './datas/protocol';
import secret from './datas/secret';

function Audit(props) {
    window.scrollTo(0, 0);
    let isState = props.match.params && props.match.params.type == "user";
    let searchParams = new URLSearchParams(props.location.search.substring(1));
    let isHideHeader = searchParams.get("hideHeader") == "true";
    let context = {
        title: isState ? '用户协议' : '隐私政策',
        richText: isState ? protocol : secret,
    }
    return (
        <div style={{ height: '100%', overflowX: 'hidden', overflowY: 'auto' }} >
            {isHideHeader ? null : <div className="headerTitle">
                <Link className="backIcon" to="/">
                    <img src={require('./assets/left-arrow.png')} />
                </Link>
                <div className="titleText">
                    {context.title}
                </div>
            </div>}

            <div className='ql-container ql-snow'>
                <div dangerouslySetInnerHTML={{ __html: context.richText }} className="ql-editor"></div>
            </div>
        </div>
    );
}

export default withRouter(Audit);