import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Audit from './Audit';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <Router>
    <div style={{ height: '100%', }}>
      <Switch>
        <Route exact path="/" ><App /></Route>
        <Route path="/audit/:type"><Audit /></Route>
        <Route path="/audit"><Audit /></Route>
      </Switch>
    </div>
  </Router>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
